import {
  FRAME_COLORS,
  MATERIAL_TYPES,
  FILTERS,
  MATTING,
} from '@mixtiles/web-backend-shared'
import uniq from 'lodash/uniq'

type FramePresetId =
  | 'frameless'
  | 'classic_black'
  | 'classic_white'
  | 'classic_wood'
  | 'wide_frame_black'
  | 'wide_frame_white'
  | 'wide_frame_red_ash'
  | 'earthy_blend'
  | 'classic_blend'
  | 'harmony_mix'

type WallPresetConfig = {
  frameColorPresetId: FramePresetId
  matting: MATTING
  filter: FILTERS
}

type WallPresetGroup = {
  id: string
  config: WallPresetConfig
}

export type WallFramePresetConfig = {
  materialType: MATERIAL_TYPES
  frameColor: FRAME_COLORS
}

export type WallFramePresetGroup = {
  id: FramePresetId
  configs: WallFramePresetConfig[]
}

export const WALL_PREVIEW_PRESET_CONFIG: WallPresetGroup[] = [
  {
    id: 'classic-wood-single',
    config: {
      frameColorPresetId: 'classic_wood',
      matting: MATTING.MEDIUM,
      filter: FILTERS.NONE,
    },
  },
  {
    id: 'classic-black-single',
    config: {
      frameColorPresetId: 'classic_black',
      matting: MATTING.DEEP,
      filter: FILTERS.SILVER,
    },
  },
  {
    id: 'frameless-single',
    config: {
      frameColorPresetId: 'frameless',
      matting: MATTING.NONE,
      filter: FILTERS.NONE,
    },
  },
  {
    id: 'mixed-frames',
    config: {
      frameColorPresetId: 'earthy_blend',
      matting: MATTING.SHALLOW,
      filter: FILTERS.NONE,
    },
  },
  {
    id: 'wide-frame-red-ash-single',
    config: {
      frameColorPresetId: 'wide_frame_red_ash',
      matting: MATTING.MEDIUM,
      filter: FILTERS.NONE,
    },
  },
  {
    id: 'wide-frame-white-single',
    config: {
      frameColorPresetId: 'wide_frame_white',
      matting: MATTING.MEDIUM,
      filter: FILTERS.NONE,
    },
  },
]

export const WALL_FRAME_PRESET_CONFIG: WallFramePresetGroup[] = [
  {
    id: 'frameless',
    configs: [
      {
        materialType: MATERIAL_TYPES.FRAMELESS,
        frameColor: FRAME_COLORS.NONE,
      },
    ],
  },
  {
    id: 'classic_black',
    configs: [
      {
        materialType: MATERIAL_TYPES.CLASSIC,
        frameColor: FRAME_COLORS.BLACK,
      },
    ],
  },
  {
    id: 'classic_white',
    configs: [
      {
        materialType: MATERIAL_TYPES.CLASSIC,
        frameColor: FRAME_COLORS.WHITE,
      },
    ],
  },
  {
    id: 'classic_wood',
    configs: [
      {
        materialType: MATERIAL_TYPES.CLASSIC,
        frameColor: FRAME_COLORS.WOOD,
      },
    ],
  },
  {
    id: 'wide_frame_black',
    configs: [
      {
        materialType: MATERIAL_TYPES.WIDE_FRAME,
        frameColor: FRAME_COLORS.BLACK,
      },
    ],
  },
  {
    id: 'wide_frame_white',
    configs: [
      {
        materialType: MATERIAL_TYPES.WIDE_FRAME,
        frameColor: FRAME_COLORS.WHITE,
      },
    ],
  },
  {
    id: 'wide_frame_red_ash',
    configs: [
      {
        materialType: MATERIAL_TYPES.WIDE_FRAME,
        frameColor: FRAME_COLORS.RED_ASH_WOOD,
      },
    ],
  },
  {
    id: 'earthy_blend',
    configs: [
      {
        materialType: MATERIAL_TYPES.CLASSIC,
        frameColor: FRAME_COLORS.WOOD,
      },
      {
        materialType: MATERIAL_TYPES.CLASSIC,
        frameColor: FRAME_COLORS.BLACK,
      },
      {
        materialType: MATERIAL_TYPES.WIDE_FRAME,
        frameColor: FRAME_COLORS.RED_ASH_WOOD,
      },
    ],
  },
  {
    id: 'classic_blend',
    configs: [
      {
        materialType: MATERIAL_TYPES.CLASSIC,
        frameColor: FRAME_COLORS.WOOD,
      },
      {
        materialType: MATERIAL_TYPES.CLASSIC,
        frameColor: FRAME_COLORS.WHITE,
      },
      {
        materialType: MATERIAL_TYPES.WIDE_FRAME,
        frameColor: FRAME_COLORS.RED_ASH_WOOD,
      },
    ],
  },
  {
    id: 'harmony_mix',
    configs: [
      {
        materialType: MATERIAL_TYPES.CLASSIC,
        frameColor: FRAME_COLORS.BLACK,
      },
      {
        materialType: MATERIAL_TYPES.CLASSIC,
        frameColor: FRAME_COLORS.WHITE,
      },
      {
        materialType: MATERIAL_TYPES.WIDE_FRAME,
        frameColor: FRAME_COLORS.BLACK,
      },
    ],
  },
]

export const DEFAULT_FRAME: FramePresetId = 'classic_black'
export const DEFAULT_MATTING: MATTING = MATTING.SHALLOW

export function getFrameFromPresetConfig(
  presetId: FramePresetId,
  frameIndex: number
): WallFramePresetConfig | null {
  const preset = WALL_FRAME_PRESET_CONFIG.find(
    (preset) => preset.id === presetId
  )
  if (!preset) {
    return null
  }

  return preset.configs[frameIndex % preset.configs.length]
}

export const getUsedMaterialsInPreset = (
  presetId: string
): MATERIAL_TYPES[] => {
  return uniq(
    WALL_FRAME_PRESET_CONFIG.find(
      (preset) => preset?.id === presetId
    )?.configs.map((config) => config.materialType as MATERIAL_TYPES) || [
      MATERIAL_TYPES.CLASSIC,
    ]
  )
}
